import { AnalyticsBrowser } from "@segment/analytics-next";
import { env } from "@xenia-libs/resident-app-utils/env.mjs";

export const analytics = new AnalyticsBrowser();

if (env.NEXT_PUBLIC_ENABLE_ANALYTICS) {
  analytics.load({
    writeKey: env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  });
}
