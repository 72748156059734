"use client";

import { usePrevious } from "@xenia-libs/hooks";
import {
  usePathname,
  useRouter,
} from "@xenia-libs/resident-app-utils/navigation";
import { AvailableSearchParams } from "@xenia-libs/resident-app-utils/types";
import { useSearchParams } from "@xenia-libs/resident-app-utils/navigation";
import { useEffect } from "react";

/**
 * Deals with a user navigation to the app with the `b=1` search param.
 * Potentially from bookmarking a page and entering the URL directly. In this case,
 * there is no previous pathname and so we can remove the b search param in order
 * for the back button to function.
 */
export function FromResidentApp() {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    const fromResidentApp = searchParams.has(
      AvailableSearchParams.FROM_RESIDENT_APP,
    );

    if (!previousPathname && fromResidentApp) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.delete(AvailableSearchParams.FROM_RESIDENT_APP);

      // Without the setTimeout, there is a race condition with the notFound function
      // called within server components.
      setTimeout(() => {
        router.replace(
          newSearchParams.size === 0
            ? pathname
            : `${pathname}?${newSearchParams.toString()}`,
          { disabledFromResidentApp: true },
        );
      }, 100);
    }
  }, [pathname, previousPathname, router, searchParams]);

  return null;
}
