"use client";
import { z } from "zod";
import {
  useForm as useReactHookForm,
  UseFormProps as RhfUseFormProps,
  UseFormReturn as RhfUseFormReturn,
  FieldValues as RhfFieldValues,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";

export function useForm<
  Schema extends z.ZodTypeAny = z.ZodTypeAny,
  Context = unknown,
>(
  props: UseFormProps<z.infer<Schema>, Schema, Context>,
): UseFormReturn<z.infer<Schema>, Context> {
  const { updateDefaultValues, defaultValues } = props;

  const formProps = useReactHookForm({
    ...props,
    resolver: props?.schema && zodResolver(props.schema),
  });

  const { reset, register } = formProps;

  const registerNumberInput: typeof register = useCallback(
    (name, options) => {
      return register(name, {
        // The value is the raw value from the input e.g. a number in a string
        setValueAs: (v) => (v ? Number(v) : undefined),
        ...options,
      });
    },
    [register],
  );

  useEffect(() => {
    if (updateDefaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, updateDefaultValues]);

  return { ...formProps, registerNumberInput };
}

/**
 * Enables the use of zod schemas with react-hook-form so that a schema can be passed
 * to the `schema` prop and the shape of the form can be inferred
 */
export type UseFormProps<
  FieldValues extends RhfFieldValues = RhfFieldValues,
  Schema extends z.ZodTypeAny = z.ZodTypeAny,
  Context = unknown,
> = RhfUseFormProps<FieldValues, Context> & {
  /**
   * The zod schema to use for validation
   */
  schema?: Schema;
  /**
   * If set to `true`, then you must memoize the `defaultValues` prop to prevent infinite loops.
   *
   * If the value of `defaultValues` changes, then see this reflected in the form. This is useful
   * when loading default data from an API.
   */
  updateDefaultValues?: boolean;
};

export type UseFormReturn<
  FieldValues extends RhfFieldValues = RhfFieldValues,
  Context = unknown,
> = RhfUseFormReturn<FieldValues, Context> & {
  registerNumberInput: RhfUseFormReturn<FieldValues, Context>["register"];
};
