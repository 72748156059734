import { env } from "@xenia-libs/resident-app-utils/env.mjs";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { PropsWithChildren } from "react";

export function FeatureFlagProvider({ children }: PropsWithChildren) {
  return (
    <LDProvider clientSideID={env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID}>
      {children}
    </LDProvider>
  );
}
