"use client";

import {
  UseZodErrorMapConfig,
  ZodErrorMapProvider,
} from "@xenia-libs/react-forms";
import { useTranslations } from "@xenia-libs/next-i18n";
import { PropsWithChildren, useMemo } from "react";

export function ZodErrorMap({ children }: PropsWithChildren) {
  const t = useTranslations("errors.forms");
  const config = useMemo<UseZodErrorMapConfig>(
    () => ({
      too_small: (error) => {
        if (error.minimum === 1) {
          return t("required");
        }

        return t("min", { min: error.minimum as number });
      },
    }),
    [t],
  );

  return <ZodErrorMapProvider config={config}>{children}</ZodErrorMapProvider>;
}
