"use client";

import { UseFormReturn } from "./use-form";
import { useContext, createContext, PropsWithChildren } from "react";
import { FieldValues as RhfFieldValues } from "react-hook-form";

export const FormContext = createContext<UseFormReturn | null>(null);
const FormContextProvider = FormContext.Provider;

export type FormProviderProps<
  FieldValues extends RhfFieldValues = RhfFieldValues,
  Context = unknown,
> = PropsWithChildren<UseFormReturn<FieldValues, Context>>;

export const FormProvider = <
  FieldValues extends RhfFieldValues = RhfFieldValues,
  TContext = unknown,
>(
  props: FormProviderProps<FieldValues, TContext>,
) => {
  const { children, ...data } = props;
  return (
    <FormContextProvider value={data as unknown as UseFormReturn}>
      {children}
    </FormContextProvider>
  );
};

export const useFormContext = <
  FieldValues extends RhfFieldValues,
>(): UseFormReturn<FieldValues> => {
  return useContext(FormContext)! as unknown as UseFormReturn<FieldValues>;
};
