import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { z } from "zod";

type InferZodIssueType<T extends z.ZodIssueCode> = Extract<
  z.ZodIssueOptionalMessage,
  { code: T }
>;

export type UseZodErrorMapConfig = {
  [Key in z.ZodIssueCode]?:
    | string
    | ((error: InferZodIssueType<Key>, ctx: z.ErrorMapCtx) => string);
};

export type ZodErrorMapOptions = {
  /**
   * Custom error messages to be used with Zod. The key is the error code, and the value is a the error message.
   * Error message can be a string or a function that receives the error and the context and returns a string.
   */
  config: UseZodErrorMapConfig;
  /**
   * If the error code is not found in the config, this message will be used as a fallback.
   */
  fallback?: string;
};

const ZodErrorMapContext = createContext<ZodErrorMapOptions | null>(null);
export const useZodErrorMap = () => useContext(ZodErrorMapContext);

export type ZodErrorMapProviderProps = PropsWithChildren<ZodErrorMapOptions>;

/**
 * Globally set custom error messages to be used with Zod, and in particular `useForm` from `@xenia-libs/react-forms`.
 * Nested providers will merge their configurations. For example, if a parent provider has a configuration for
 * `too_small` and a child provider also has a configuration for `too_small`, the child configuration will override
 * the parent configuration.
 * @see https://zod.dev/ERROR_HANDLING?id=customizing-errors-with-zoderrormap
 * @example
 * ```tsx
 * export function Providers({ children }) {
 *   return (
 *     <ZodErrorMapProvider
 *       config={{
 *         too_small: 'Value too small!',
 *         too_big: () => `Max size is ${error.maximum}`
 *       }}
 *       fallback="Invalid"
 *     >
 *       {children}
 *     </ZodErrorMapProvider>
 *   );
 * }
 */
export function ZodErrorMapProvider({
  config,
  fallback,
  children,
}: ZodErrorMapProviderProps) {
  const existingConfig = useZodErrorMap();

  const errorMap = useMemo(() => {
    return {
      ...existingConfig?.config,
      ...config,
    };
  }, [config, existingConfig]);

  z.setErrorMap((error, ctx) => {
    const errorConfig = errorMap[error.code];

    let message =
      (typeof errorConfig === "function"
        ? errorConfig(error as any, ctx)
        : errorConfig) ||
      fallback ||
      existingConfig?.fallback;

    if (!message) {
      message = ctx.defaultError;
      console.warn(
        `No error message found for error code ${error.code}. Falling back to default message which is not translated: "${message}"`,
      );
    }

    return { message };
  });

  return (
    <ZodErrorMapContext.Provider value={{ config: errorMap, fallback }}>
      {children}
    </ZodErrorMapContext.Provider>
  );
}
