import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const coerceBoolean = z
  .string()
  .optional()
  .transform((value) => value === "true");

export const env = createEnv({
  server: {
    LAUNCHDARKLY_SDK_KEY: z.string().min(1),
    AUTH_CLIENT_PRIVATE_KEY: z
      .string()
      .min(1)
      .transform((value) => Buffer.from(value, "base64").toString()),
    AUTH0_SECRET: z.string().min(1),
    CONFIG_REVALIDATE_SECONDS: z.coerce.number().int().gte(0),
  },
  client: {
    NEXT_PUBLIC_PLATAPI_URL: z.string().min(1),
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().min(1),
    NEXT_PUBLIC_ENABLE_ANALYTICS: coerceBoolean,
    NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: z.string().min(1),
    NEXT_PUBLIC_CMS_URL: z.string().min(1),
    NEXT_PUBLIC_FULLSTORY_ORG_ID: z.string().optional(),
    NEXT_PUBLIC_ENVIRONMENT: z
      .enum(["development", "staging", "production"])
      .default("development"),
    NEXT_PUBLIC_ENABLE_FULLSTORY: coerceBoolean,
    NEXT_PUBLIC_ARTICLE_FALLBACK_IMAGE: z.string().min(1),
  },
  emptyStringAsUndefined: true,
  runtimeEnv: {
    NEXT_PUBLIC_PLATAPI_URL: process.env.NEXT_PUBLIC_PLATAPI_URL,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    NEXT_PUBLIC_ENABLE_ANALYTICS: process.env.NEXT_PUBLIC_ENABLE_ANALYTICS,
    NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID:
      process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
    LAUNCHDARKLY_SDK_KEY: process.env.LAUNCHDARKLY_SDK_KEY,
    AUTH_CLIENT_PRIVATE_KEY: process.env.AUTH_CLIENT_PRIVATE_KEY,
    AUTH0_SECRET: process.env.AUTH0_SECRET,
    CONFIG_REVALIDATE_SECONDS: process.env.CONFIG_REVALIDATE_SECONDS,
    NEXT_PUBLIC_CMS_URL: process.env.NEXT_PUBLIC_CMS_URL,
    NEXT_PUBLIC_FULLSTORY_ORG_ID: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_ENABLE_FULLSTORY: process.env.NEXT_PUBLIC_ENABLE_FULLSTORY,
    NEXT_PUBLIC_ARTICLE_FALLBACK_IMAGE:
      process.env.NEXT_PUBLIC_ARTICLE_FALLBACK_IMAGE,
  },
  skipValidation: process.env.SKIP_ENV_VALIDATION === "true",
});
