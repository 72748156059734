"use client";

import { useEffect } from "react";
import { analytics } from "@xenia-apps/resident-app/lib/analytics";
import {
  useSearchParams,
  usePathname,
} from "@xenia-libs/resident-app-utils/navigation";
import { init as initFullStory } from "@fullstory/browser";
import { env } from "@xenia-libs/resident-app-utils/env.mjs";
import { Analytics as VercelAnalytics } from "@vercel/analytics/react";

export function Analytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    analytics.page();
  }, [pathname, searchParams]);

  useEffect(() => {
    if (env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
      initFullStory({
        orgId: env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
        devMode: !env.NEXT_PUBLIC_ENABLE_FULLSTORY,
      });
    }
  }, []);

  return <VercelAnalytics />;
}
